.wrapper-loader {
  margin: 0;
  height: 95px;
  width: 105px; }

svg#loader {
  animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
  -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }

@keyframes fadeInChildren {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

.page-loader {
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: fadeInLoader 500ms ease-in-out 0s 1;
  -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }

.page-loader-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10; }
  .page-loader-content.has-copy {
    background: #fff;
    border: solid 1px #303030;
    height: 70%;
    max-width: 500px;
    max-height: 300px;
    width: 80%; }

.page-loader-copy {
  max-width: 250px;
  padding: 35px 0 0;
  text-align: center;
  width: 80%; }
  @media screen and (max-height: 380px) {
    .page-loader-copy {
      padding-top: 5px; } }

@keyframes fadeInLoader {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; } }

html, body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif; }
  html .App, html .Index, html .client-page, body .App, body .Index, body .client-page {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 70px;
    padding-top: 50px; }
  html .client-page, body .client-page {
    padding-bottom: 80px; }
    @media screen and (min-width: 480px) {
      html .client-page, body .client-page {
        padding-bottom: 110px; } }
    @media screen and (min-width: 650px) {
      html .client-page, body .client-page {
        padding-bottom: 91px; } }
  html .no-scrolling, body .no-scrolling {
    overflow: hidden; }
  html .client-content, body .client-content {
    padding: 10px;
    min-height: 100%;
    background-color: #f1f1f2; }
    html .client-content .content-card, body .client-content .content-card {
      background-color: #FFF;
      max-width: 920px;
      margin: auto; }
      html .client-content .content-card .sao-field:last-child, body .client-content .content-card .sao-field:last-child {
        margin-bottom: 0; }
    @media screen and (min-width: 480px) {
      html .client-content, body .client-content {
        padding: 20px; } }
  html .title-wrapper, body .title-wrapper {
    max-width: 960px;
    margin: auto auto 20px; }
    html .title-wrapper h1, body .title-wrapper h1 {
      margin-bottom: 10px;
      font-size: 30px;
      outline: none; }
      @media screen and (min-width: 768px) {
        html .title-wrapper h1, body .title-wrapper h1 {
          font-size: 38px; } }
    html .title-wrapper h2, body .title-wrapper h2 {
      color: #000;
      font-size: 16px;
      margin-bottom: 15px; }
      html .title-wrapper h2:last-child, body .title-wrapper h2:last-child {
        margin-bottom: 0; }
    html .title-wrapper a, body .title-wrapper a {
      text-decoration: none;
      color: #ad1982; }
  html .error, body .error {
    border: 4px solid #d70028; }
    html .error.date-input-container, body .error.date-input-container {
      max-width: max-content;
      padding: 0 10px 5px 10px; }
  html ::-ms-clear, body ::-ms-clear {
    width: 0;
    height: 0; }
  html ::-ms-reveal, body ::-ms-reveal {
    width: 0;
    height: 0; }
  html .components-smallprint, body .components-smallprint {
    margin-top: 2px;
    font-size: 13px; }
  html .components-checkbox, body .components-checkbox {
    -webkit-flex-wrap: nowrap;
    display: -webkit-inline-flex; }
    html .components-checkbox .checkbox, body .components-checkbox .checkbox {
      max-width: 27px; }
  html .components-button.link-style-button, html .components-button.link-style-button:hover, html .components-button.link-style-button:active, body .components-button.link-style-button, body .components-button.link-style-button:hover, body .components-button.link-style-button:active {
    background: none;
    box-shadow: none;
    text-shadow: none;
    font-weight: normal;
    color: #ad1982;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    padding: 0;
    line-height: 16px;
    min-width: 0; }
  html .components-button-group.submit, body .components-button-group.submit {
    max-width: 960px;
    margin: auto;
    flex-direction: column; }
    @media screen and (min-width: 400px) {
      html .components-button-group.submit, body .components-button-group.submit {
        flex-direction: row; } }
    html .components-button-group.submit .components-button.primarySubmit, body .components-button-group.submit .components-button.primarySubmit {
      flex: 0 0 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      height: 54px; }
      @media screen and (min-width: 400px) {
        html .components-button-group.submit .components-button.primarySubmit, body .components-button-group.submit .components-button.primarySubmit {
          min-width: 120px;
          flex: 0 0 120px; } }
    html .components-button-group.submit .components-button.secondarySubmit, body .components-button-group.submit .components-button.secondarySubmit {
      flex: 0 0 100%;
      max-width: none;
      margin-left: 0px; }
      @media screen and (min-width: 400px) {
        html .components-button-group.submit .components-button.secondarySubmit, body .components-button-group.submit .components-button.secondarySubmit {
          flex: 1 1 auto;
          max-width: 235px;
          margin-left: 40px; } }
  html a.button-styled-link, body a.button-styled-link {
    font-family: RNHouseSansBold;
    padding: 15px 40px;
    border-radius: 4px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    fill: #fff;
    line-height: 44px;
    font-size: 18px;
    text-shadow: 1px 1px #2a828e;
    background-color: #34a2b2;
    box-shadow: inset 0 1px #6dc7d4, 0 2px #2a828e;
    min-width: 185px;
    text-decoration: none; }
    html a.button-styled-link:hover, body a.button-styled-link:hover {
      box-shadow: 0 2px #6dc7d4, inset 0 1px #2a828e;
      color: #fff;
      fill: #fff; }
    html a.button-styled-link:active, body a.button-styled-link:active {
      box-shadow: 0 2px #6dc7d4, inset 0 1px #2a828e;
      background-color: #2a828e;
      color: #fff;
      fill: #fff; }
  html input:focus::-webkit-contacts-auto-fill-button, html input:focus::-webkit-credentials-auto-fill-button, body input:focus::-webkit-contacts-auto-fill-button, body input:focus::-webkit-credentials-auto-fill-button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: none !important;
    height: 0;
    width: 0;
    margin: 0; }

.lookup-address .postcode-lookup-field {
  padding-bottom: 20px; }
  .lookup-address .postcode-lookup-field .postcode-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .lookup-address .postcode-lookup-field .postcode-wrapper .find-my-address-again {
      margin-left: 20px; }
    .lookup-address .postcode-lookup-field .postcode-wrapper .find-my-address {
      margin-left: 20px;
      max-width: 202px;
      flex-grow: 1; }
    .lookup-address .postcode-lookup-field .postcode-wrapper .components-validation {
      flex: 1 0 100%;
      flex-basis: auto;
      max-width: 100%;
      width: 100%; }
    .lookup-address .postcode-lookup-field .postcode-wrapper .postcode {
      width: 110px; }
      .lookup-address .postcode-lookup-field .postcode-wrapper .postcode input {
        text-transform: uppercase; }

.lookup-address .address-details-wrapper {
  margin-bottom: 26px; }

.lookup-address .horziontal-line {
  background-color: #979797;
  height: 1px;
  margin-bottom: 20px;
  max-width: 688px;
  margin-left: 0; }

.lookup-address .flat-number {
  width: 100px; }

.lookup-address .date-input-container label {
  margin-bottom: 5px; }

.lookup-address .label-with-small-print {
  margin-bottom: 0; }

.manual-address .chevron-left {
  vertical-align: baseline;
  margin-right: 5px; }

.manual-address .postcode {
  width: 110px; }
  .manual-address .postcode input {
    text-transform: uppercase; }

.manual-address .date-input-container label {
  margin-bottom: 5px; }

.manual-address .label-with-small-print {
  margin-bottom: 4px; }

.client-modal .navigation-inner {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  min-height: 100%; }
  .client-modal .navigation-inner h3 {
    padding-top: 30px;
    margin-bottom: 0; }
  .client-modal .navigation-inner p:first-of-type {
    margin-top: 20px; }
  .client-modal .navigation-inner p:last-of-type {
    margin-bottom: 30px; }
  @media screen and (min-width: 340px) {
    .client-modal .navigation-inner {
      padding: 0 30px; } }
  .client-modal .navigation-inner .navigation-button-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px; }
    .client-modal .navigation-inner .navigation-button-wrapper div {
      display: flex;
      flex-basis: auto;
      justify-content: center;
      width: 100%; }
    .client-modal .navigation-inner .navigation-button-wrapper .components-button.primary {
      font-size: 18px;
      padding: 0 9px;
      width: 100%;
      height: 54px; }
    .client-modal .navigation-inner .navigation-button-wrapper .components-button.action {
      margin-left: 0; }
    .client-modal .navigation-inner .navigation-button-wrapper div:last-of-type {
      padding-top: 30px; }
    @media screen and (min-width: 400px) and (min-height: 415px) {
      .client-modal .navigation-inner .navigation-button-wrapper {
        flex-direction: column; }
        .client-modal .navigation-inner .navigation-button-wrapper div {
          justify-content: flex-start; }
        .client-modal .navigation-inner .navigation-button-wrapper .components-button.primary {
          padding: 0 40px;
          width: 292px; } }
    @media screen and (min-width: 601px) and (min-height: 415px) {
      .client-modal .navigation-inner .navigation-button-wrapper {
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        flex-basis: inherit;
        width: 495px; }
        .client-modal .navigation-inner .navigation-button-wrapper div {
          width: auto; }
        .client-modal .navigation-inner .navigation-button-wrapper div:last-of-type {
          padding-top: 0; }
        .client-modal .navigation-inner .navigation-button-wrapper .components-button.primary {
          margin-right: 30px; } }

.display-box-container {
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  overflow: hidden;
  margin-top: 20px;
  background-color: #f7f7f5; }
  @media screen and (min-width: 640px) {
    .display-box-container {
      max-width: 688px; } }
  .display-box-container .display-box-heading {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    margin: 0; }
    @media screen and (min-width: 640px) {
      .display-box-container .display-box-heading {
        display: inline-block;
        width: 41.5%;
        padding: 17px 20px; } }
    .display-box-container .display-box-heading p {
      margin-top: 0px;
      margin-bottom: 0px; }
  .display-box-container .display-box-body {
    min-height: 50px;
    box-sizing: border-box;
    vertical-align: top;
    padding: 15px;
    background-color: #fff; }
    @media screen and (min-width: 640px) {
      .display-box-container .display-box-body {
        display: inline-block;
        width: 58.5%;
        padding: 17px 20px; } }

.exit-button.components-button.exit {
  float: right;
  height: 40px;
  margin-right: 0; }
  .exit-button.components-button.exit span {
    vertical-align: bottom;
    font-size: 13px; }
  .exit-button.components-button.exit svg {
    vertical-align: sub; }
  @media screen and (min-width: 480px) {
    .exit-button.components-button.exit {
      height: 50px;
      width: 135px;
      line-height: 50px; }
      .exit-button.components-button.exit span {
        font-size: 16px; } }
  @media screen and (min-width: 1440px) {
    .exit-button.components-button.exit {
      width: 152px; } }

.sao-field {
  display: flex;
  flex-direction: column;
  padding-bottom: 26px; }
  .sao-field .components-validation {
    max-width: 340px; }
  .sao-field input, .sao-field select {
    width: 100%;
    max-width: 340px;
    box-sizing: border-box;
    height: 44px; }
  .sao-field label {
    display: flex;
    margin-bottom: 11px; }
  .sao-field.read-only {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0; }
    .sao-field.read-only.stacked {
      flex-direction: column; }
    .sao-field.read-only .components-input {
      border: none;
      box-sizing: content-box;
      padding-left: 0;
      height: 20px;
      max-width: max-content;
      min-width: 100%;
      font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif; }
    .sao-field.read-only label {
      align-self: flex-start;
      margin-bottom: 8px;
      min-width: fit-content;
      color: #42145f; }
    .sao-field.read-only.no-label label {
      margin-bottom: 2px; }

.inline-icon {
  display: inline;
  height: 12px;
  vertical-align: bottom; }

.sao-footer {
  height: 49px;
  font-size: 13px;
  background-color: #f1f1f2;
  line-height: 26px;
  border-top: 1px solid #D8D8D8; }
  @media screen and (min-width: 650px) {
    .sao-footer {
      line-height: 33px; } }
  .sao-footer ul {
    padding: 8px 0px;
    margin: 0px auto;
    text-align: center;
    width: 320px; }
    @media screen and (min-width: 650px) {
      .sao-footer ul {
        width: 620px; } }
    @media screen and (min-width: 1280px) {
      .sao-footer ul {
        text-align: left;
        width: 960px; } }
    .sao-footer ul li {
      display: inline-flex; }
      .sao-footer ul li span {
        white-space: pre;
        color: #000; }
  .sao-footer.loading {
    opacity: 0.4; }

.sao-header {
  height: 40px;
  width: 100%;
  background: #42145f;
  margin-top: -50px; }
  @media screen and (min-width: 480px) {
    .sao-header {
      height: 50px; } }
  .sao-header .header-content {
    margin: auto; }
    @media screen and (min-width: 1440px) {
      .sao-header .header-content {
        max-width: 960px; } }
  .sao-header .brand-logo {
    width: 105px;
    height: 18px;
    padding: 12px; }
    @media screen and (min-width: 480px) {
      .sao-header .brand-logo {
        height: 20px;
        padding: 15px 20px; } }
    @media screen and (min-width: 1440px) {
      .sao-header .brand-logo {
        padding: 15px 0px; } }
  .sao-header.loading {
    opacity: 0.4; }

@keyframes dots-animate {
  10% {
    fill: rgba(173, 25, 130, 0.27);
    r: 15; }
  50% {
    fill: #ad1982;
    r: 18; }
  90% {
    fill: rgba(173, 25, 130, 0.27);
    r: 13; } }

.in-page-loader {
  display: inline-block;
  vertical-align: top;
  height: 40px; }
  .in-page-loader svg {
    fill: rgba(173, 25, 130, 0.27);
    width: 35px;
    display: inline-block;
    height: 33px;
    margin: 0 46px;
    vertical-align: middle; }
    .in-page-loader svg .badge-part {
      animation: dots-animate 0.8s ease-in infinite; }
      .in-page-loader svg .badge-part:nth-child(2) {
        animation-delay: 0.22s; }
      .in-page-loader svg .badge-part:nth-child(3) {
        animation-delay: 0.57s; }

.client-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); }
  .client-modal:focus {
    outline: none; }
  .client-modal .modal-inner {
    padding: 0;
    margin: 40px 20px;
    max-width: 600px; }
    @media screen and (min-width: 520px) {
      .client-modal .modal-inner {
        margin-left: 40px;
        margin-right: 40px; } }
    @media screen and (min-width: 680px) {
      .client-modal .modal-inner {
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (min-width: 480px) and (min-height: 415px) {
      .client-modal .modal-inner {
        margin-top: 100px; } }
    .client-modal .modal-inner .default-modal {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 5px 20px; }
      .client-modal .modal-inner .default-modal p {
        margin: 0 0 20px 0; }
      .client-modal .modal-inner .default-modal .modal-heading {
        display: inline-flex; }
        .client-modal .modal-inner .default-modal .modal-heading h3 {
          font-size: 30px;
          flex-grow: 1; }
          @media screen and (min-width: 400px) {
            .client-modal .modal-inner .default-modal .modal-heading h3 {
              margin-top: 10px; } }
        .client-modal .modal-inner .default-modal .modal-heading .modal-close-button {
          width: 32px;
          height: 32px; }
          .client-modal .modal-inner .default-modal .modal-heading .modal-close-button svg {
            fill: #ad1982; }
            .client-modal .modal-inner .default-modal .modal-heading .modal-close-button svg :hover {
              fill: #42145f; }
      .client-modal .modal-inner .default-modal .components-button-group div {
        width: 100%; }
        .client-modal .modal-inner .default-modal .components-button-group div button {
          width: 100%;
          margin-left: 0;
          margin-bottom: 25px; }
        @media screen and (min-width: 400px) {
          .client-modal .modal-inner .default-modal .components-button-group div {
            width: auto; }
            .client-modal .modal-inner .default-modal .components-button-group div button {
              width: auto; }
            .client-modal .modal-inner .default-modal .components-button-group div .primary {
              margin-right: 40px; }
            .client-modal .modal-inner .default-modal .components-button-group div .action {
              margin-top: 0; } }
    .client-modal .modal-inner.confirmation-modal {
      margin-top: 95px;
      max-width: 748px;
      min-height: 0px;
      padding: 0; }
      .client-modal .modal-inner.confirmation-modal .modal-heading h3 {
        font-size: 20px; }
        @media screen and (min-width: 400px) {
          .client-modal .modal-inner.confirmation-modal .modal-heading h3 {
            font-size: 30px; } }
    .client-modal .modal-inner.register-modal {
      width: 300px;
      max-width: 300px;
      margin: 20px auto;
      padding-bottom: 20px; }
      .client-modal .modal-inner.register-modal .modal-heading h3 {
        font-size: 20px;
        font-weight: normal; }
      .client-modal .modal-inner.register-modal .modal-customer-replay span {
        display: block;
        font-weight: bold; }
        .client-modal .modal-inner.register-modal .modal-customer-replay span:last-child {
          color: #42145f;
          margin-bottom: 5px; }
      .client-modal .modal-inner.register-modal .components-button-group div {
        width: 100%; }
        .client-modal .modal-inner.register-modal .components-button-group div .primary {
          width: 100%;
          height: 100%;
          margin-right: 0; }

.loader-footer {
  position: absolute;
  bottom: 0;
  width: 100%; }

.page-loader {
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.page-loader-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10; }

.page-loader-copy {
  padding: 35px 0 0;
  text-align: center; }

.view-timeout-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background-color: #42145f;
  z-index: 99;
  line-height: 1.31;
  height: auto;
  -webkit-transition: height .4s ease-in-out;
  -moz-transition: height .4s ease-in-out;
  -o-transition: height .4s ease-in-out;
  transition: height .4s ease-in-out; }
  .view-timeout-warning p {
    text-align: center;
    margin: 25px auto; }
  .view-timeout-warning span {
    font-weight: 700; }
  .view-timeout-warning .timeout-continue-button, .view-timeout-warning .timeout-continue-button:hover {
    cursor: pointer;
    color: #fff;
    text-decoration: underline; }

.accessibility-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }
