.react-spinner {
  position: relative;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: #1d7b8a;
  position: absolute;
  width: 30%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

@keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-moz-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-webkit-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

.spinner-vertical-align{
 	position: absolute;
	margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* spinner delay/rotate [start] */

.spinner-rotate-0{ 
  animation-delay: -1.6s; 
  transform: rotate(0deg) translate(146%); 
 }
 .spinner-rotate-1{ 
  animation-delay: -1.5s; 
  transform: rotate(22.5deg) translate(146%); 
 }
 .spinner-rotate-2{ 
  animation-delay: -1.4s; 
  transform: rotate(45deg) translate(146%); 
 }
 .spinner-rotate-3{ 
  animation-delay: -1.3s; 
  transform: rotate(67.5deg) translate(146%); 
 }
 .spinner-rotate-4{ 
  animation-delay: -1.2s; 
  transform: rotate(90deg) translate(146%); 
 }
 .spinner-rotate-5{ 
  animation-delay: -1.1s; 
  transform: rotate(112.5deg) translate(146%); 
 }
 .spinner-rotate-6{ 
  animation-delay: -1s; 
  transform: rotate(135deg) translate(146%); 
 }
 .spinner-rotate-7{ 
  animation-delay: -0.9s; 
  transform: rotate(157.5deg) translate(146%); 
 }
 .spinner-rotate-8{ 
  animation-delay: -0.8s; 
  transform: rotate(180deg) translate(146%); 
 }
 .spinner-rotate-9{ 
  animation-delay: -0.7s; 
  transform: rotate(202.5deg) translate(146%); 
 }
 .spinner-rotate-10{ 
  animation-delay: -0.6s; 
  transform: rotate(225deg) translate(146%); 
 }
 .spinner-rotate-11{ 
  animation-delay: -0.5s; 
  transform: rotate(247.5deg) translate(146%); 
 }
 .spinner-rotate-12{ 
  animation-delay: -0.4s; 
  transform: rotate(270deg) translate(146%); 
 }
 .spinner-rotate-13{ 
  animation-delay: -0.3s; 
  transform: rotate(292.5deg) translate(146%); 
 }
 .spinner-rotate-14{ 
  animation-delay: -0.2s; 
  transform: rotate(315deg) translate(146%); 
 }
 .spinner-rotate-15{ 
  animation-delay: -0.1s; 
  transform: rotate(337.5deg) translate(146%); 
 }

 /* spinner rotate [end] */