.wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-button {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif;
    padding: 0 12px;
    border-radius: 4px;
    border-style: none;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box; }
    .components-button.primary {
      color: #fff;
      fill: #fff;
      line-height: 44px;
      font-size: 19px;
      text-shadow: 1px 1px #2a828e;
      background-color: #34a2b2;
      box-shadow: inset 0 1px #6dc7d4, 0 2px #2a828e;
      min-width: 185px; }
      .components-button.primary:focus, .components-button.primary:hover {
        box-shadow: 0 2px #6dc7d4, inset 0 1px #2a828e;
        color: #fff;
        fill: #fff; }
      .components-button.primary:active {
        box-shadow: 0 2px #6dc7d4, inset 0 1px #2a828e;
        background-color: #2a828e;
        color: #fff;
        fill: #fff; }
      .components-button.primary.disabled {
        cursor: default; }
    .components-button.secondary {
      box-shadow: inset 0 0 0 1px #ad1982;
      line-height: 44px;
      font-size: 16px;
      color: #ad1982;
      fill: #ad1982;
      background-color: #fff; }
      .components-button.secondary:focus, .components-button.secondary:hover {
        boxShadow: inset 0 0 0 4px #ad1982;
        color: #ad1982;
        fill: #ad1982;
        text-decoration: underline; }
      .components-button.secondary:active {
        background-color: #ad1982;
        color: #fff;
        fill: #fff; }
      .components-button.secondary.disabled {
        cursor: default; }
    .components-button.action {
      font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
      font-size: 16px;
      padding: 0;
      background-color: transparent;
      color: #ad1982;
      fill: #ad1982;
      text-decoration: underline; }
      .components-button.action:hover {
        text-decoration: none; }
      .components-button.action:focus {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto; }
      .components-button.action.disabled {
        cursor: default;
        color: #f1f1f2; }
    .components-button.exit {
      border: none;
      box-shadow: none;
      background-color: #ad1982;
      border-radius: 0;
      line-height: 40px;
      min-width: 93px;
      font-size: 16px; }
      .components-button.exit:active, .components-button.exit:hover {
        background-color: #82186b; }
      .components-button.exit span {
        text-shadow: none;
        padding-right: 10px;
        font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
        vertical-align: middle;
        color: #fff; }
      .components-button.exit svg {
        vertical-align: middle; }
      .components-button.exit.disabled {
        cursor: default; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-link {
    color: #ad1982;
    fill: #ad1982;
    text-decoration: underline;
    cursor: pointer; }
    .components-link:hover {
      text-decoration: none; }
    .components-link.with-image {
      text-decoration: none; }
      .components-link.with-image img {
        margin-right: 5px;
        vertical-align: middle; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-h1 {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 38px;
    margin-bottom: 30px;
    margin-top: 0;
    color: #42145f;
    fill: #42145f;
    font-weight: normal; }
  
  .components-h2 {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 30px;
    margin-bottom: 30px;
    margin-top: 0;
    color: #42145f;
    fill: #42145f;
    font-weight: normal; }
  
  .components-h3 {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 0;
    color: #42145f;
    fill: #42145f;
    font-weight: normal; }
  
  .components-h4 {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 0;
    color: #42145f;
    fill: #42145f;
    font-weight: normal; }
  
  .components-h5 {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 13px;
    margin-bottom: 13px;
    margin-top: 0;
    color: #42145f;
    fill: #42145f;
    font-weight: normal; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-paragraph {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 18px;
    margin-top: 0;
    color: #000;
    fill: #000; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-smallprint {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin-bottom: 12px;
    margin-top: 0;
    color: #000;
    fill: #000; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-input {
    max-width: 340px;
    font-size: 16px;
    height: 44px;
    border-radius: 3px;
    padding: 0;
    border: none; }
    .components-input input {
      border: 1px solid #808080;
      padding: 0 16px; }
    .components-input input:invalid {
      box-shadow: none; }
    .components-input.error input {
      border: none; }
    .components-input.symbol {
      display: flex;
      border: 1px solid #808080; }
      .components-input.symbol.error {
        border: 4px solid #d70028; }
      .components-input.symbol span {
        margin: 13px 10px;
        border-style: none; }
      .components-input.symbol input {
        border: none;
        width: 100%;
        padding: 0;
        -moz-appearance: textfield; }
        .components-input.symbol input.error {
          border: none; }
      .components-input.symbol input[type=number]::-webkit-inner-spin-button, .components-input.symbol input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .components-input.symbol.focussed {
        outline: 2px solid Highlight; }
        .components-input.symbol.focussed input:focus {
          outline: 0; }
        @media (-webkit-min-device-pixel-ratio: 0) {
          .components-input.symbol.focussed {
            outline: auto -webkit-focus-ring-color; } }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-infobox-container {
    position: relative;
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid #979797;
    background-color: #f7f7f5; }
  
  .components-infobox-arrow {
    left: 12px;
    top: -5px; }
  
  .components-infobox-content {
    padding: 15px;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    display: inline-block;
    color: #000;
    fill: #000; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-arrow-holder {
    position: absolute;
    overflow: hidden;
    background-color: transparent;
    border: none;
    top: -6px; }
    .components-arrow-holder .components-arrow-arrow {
      width: 8.48526px;
      height: 8.48526px;
      margin: 1.75737px; }
    .components-arrow-holder .components-arrow-holder {
      width: 12px;
      height: 6px; }
  
  .components-arrow-arrow {
    border: 1px solid #979797;
    background-color: #f7f7f5;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    box-sizing: border-box;
    transform: rotate(45deg); }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-validation .components-arrow-arrow {
    width: 8.48526px;
    height: 8.48526px;
    margin: 1.75737px; }
  
  .components-validation .components-arrow-holder {
    width: 12px;
    height: 6px; }
  
  .components-validation .components-infobox-container {
    border: 1px solid #d70028;
    background-color: #f8d1d8; }
  
  .components-validation .components-arrow-arrow {
    border: 1px solid #d70028;
    background-color: #f8d1d8; }
  
  .components-validation .components-arrow-holder {
    left: 10px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-group-base {
    border-width: 1px;
    border-style: solid;
    border-color: #808080;
    border-radius: 3px;
    display: flex;
    overflow: hidden; }
  
  .components-group-base-focused {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto; }
  
  .components-group-item-border {
    border-radius: 0;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #808080; }
  
  .components-group-item-noBorder {
    border-width: 0; }
  
  .components-group-item-noFocus:focus {
    outline-style: none; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-select {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    border-radius: 3px;
    height: 44px;
    padding-left: 16px;
    padding-right: 36px;
    border-width: 1px;
    border-style: solid;
    border-color: #979797;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
   /* background: url("assets/images/arrow.png") no-repeat 97% 50% #fff;*/
    background-size: 17px 10px; }
    .components-select:hover, .components-select:focus {
      background-color: #fff; }
    .components-select::-ms-expand {
      display: none; }
  
  .components-optiongroup--disable {
    font-weight: 100; }
  
  .components-option {
    padding: 15px; }
    .components-option:checked, .components-option:hover {
      box-shadow: 0 0 10px 100px #ad1982 inset; }
    .components-option:checked {
      font-weight: bold; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-groupedList {
    list-style: none;
    padding: 0;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 13px;
    box-shadow: 0 1px 2px 0 #808080;
    border-radius: 4px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-group {
    list-style: none;
    padding: 0; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-item {
    padding: 26px 10px;
    display: inline-block; }
  
  .components-item-border {
    border-bottom: 1px solid #f1f1f2; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-heading {
    padding: 7px 10px;
    background-color: #f1f1f2; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-accessibilitytext {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-horizontalline {
    margin-top: 0;
    margin-bottom: 0;
    border-top: none;
    border-bottom: 1px solid #f1f1f2; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-help-button {
    font-size: 13px;
    color: #ad1982;
    padding-left: 0; }
  
  .components-help-arrow {
    fill: #ad1982;
    height: 15px;
    width: 8px;
    vertical-align: bottom;
    padding-left: 4px; }
  
  .components-help-infobox-base {
    text-align: left; }
  
  .components-help-infobox-arrow {
    background-color: infoboxcolor;
    border: 1px solid-infoboxcolor; }
  
  .components-help-infobox-content {
    background-color: infoboxcolor;
    border: 1px solid-infoboxcolor; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-tab-container {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 12px 0;
    box-sizing: border-box;
    color: #ad1982;
    text-align: center; }
  
  .components-tab-container-checked {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif; }
  
  .components-tab-container-focused {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto; }
  
  .components-tab-baseline {
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: #f1f1f2; }
  
  .components-tab-baseline-checked {
    height: 3px;
    background-color: #ad1982; }
  
  .components-tab-arrow {
    background-color: #ad1982;
    left: 50%;
    bottom: -8px;
    margin-left: -8px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-paginationDesktop-pagination {
    text-align: center;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    color: #ad1982;
    position: relative; }
  
  .components-paginationDesktop-spacing {
    margin: 0 15px;
    padding: 0 4px;
    border-radius: 0; }
  
  .components-paginationDesktop-previous {
    position: absolute;
    left: 0; }
  
  .components-paginationDesktop-next {
    position: absolute;
    right: 0; }
  
  .components-paginationDesktop-arrow-base {
    height: 16px;
    width: 8px; }
  
  .components-paginationDesktop-arrow-left {
    margin: 0 9px 0 0; }
  
  .components-paginationDesktop-arrow-right {
    margin: 0 0 0 9px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-paginationMobile-pagination {
    text-align: center;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    color: #ad1982;
    position: relative; }
    .components-paginationMobile-pagination select {
      display: inline-block; }
  
  .components-paginationMobile-previous {
    position: absolute;
    left: 0;
    padding: 0 10px 0 0; }
  
  .components-paginationMobile-next {
    position: absolute;
    right: 0;
    padding: 0 0 0 10px; }
  
  .components-paginationMobile-arrow {
    height: 24px;
    width: 16px;
    margin-top: 13px; }
  
  .components-paginationMobile-totalPages {
    margin-left: 14px;
    color: #000;
    word-spacing: 6px; }
  
  .components-card {
    background-color: #f7f7f5;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 16px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .date-input {
    display: flex;
    flex-direction: column; }
    .date-input-container {
      flex-direction: row;
      align-self: flex-start; }
      .date-input-container span {
        border: none; }
    .date-input-item {
      margin-bottom: 2px; }
      .date-input-item label {
        margin: 7px 0px 4px 5px;
        font-size: 13px; }
      .date-input-item input[type=number] {
        -moz-appearance: textfield; }
      .date-input-item input[type=number]::-webkit-inner-spin-button,
      .date-input-item input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .date-input-item input {
        padding: 0 8px;
        text-align: center; }
      .date-input-item .day input, .date-input-item .month input, .date-input-item .year_short input {
        width: 45px; }
      .date-input-item .year_long input {
        width: 65px; }
  
  .separator-stroke-container {
    align-self: flex-end;
    border: 0; }
    .separator-stroke-container .separator-stroke {
      stroke: #808080;
      stroke-width: 1; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .table-desktop {
    width: 100%;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 #808080;
    border-collapse: collapse;
    overflow: hidden; }
  
  .table-desktop-header-row {
    width: auto;
    background-color: #fff; }
  
  .table-desktop-header-cell {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: left;
    padding: 18px 20px;
    border-top: 1px solid #f7f7f5;
    border-bottom: 1px solid #f7f7f5; }
  
  .table-column-rightAlign {
    text-align: right; }
  
  .table-desktop-heading-sorter-button {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif;
    border-radius: 0;
    cursor: pointer;
    padding: 0 0 2px 0; }
  
  .table-desktop-heading-sorter-icons {
    height: 16px !important;
    margin-left: 8px; }
  
  .table-desktop-row {
    background-color: #f1f1f2; }
  
  .table-desktop-row-odd {
    background-color: #fff; }
  
  .table-desktop-row-cell {
    font-size: 16px;
    padding: 20px 20px; }
  
  .table-desktop-row-cell-expander-button-base {
    width: 100%;
    padding: 20px 20px;
    color: #000; }
    .table-desktop-row-cell-expander-button-base:hover {
      color: #000; }
  
  .table-desktop-row-cell-expander-arrow-base {
    height: 22px !important;
    width: 22px;
    float: right;
    transform: rotate(0deg);
    transition: transform 0.1s; }
  
  .table-desktop-row-cell-expander-arrow-open {
    transform: rotate(180deg); }
  
  .table-desktop-extended-column {
    width: 6%; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .table-mobile-header-button-base {
    width: 100%;
    text-align: left;
    padding: 26px 13px;
    color: #000; }
    .table-mobile-header-button-base:hover {
      color: #000; }
  
  .table-mobile-header-button-active {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif; }
  
  .table-mobile-header-cell-arrow-base {
    height: 22px !important;
    float: right;
    transform: rotate(0deg);
    transition: transform 0.1s; }
  
  .table-mobile-header-cell-arrow-open {
    transform: rotate(180deg); }
  
  .table-mobile-cell-list {
    margin-top: 0;
    margin-bottom: 0;
    padding: 13px; }
  
  .table-mobile-cell-list-item-container {
    overflow: hidden; }
  
  .table-mobile-cell-list-item-base {
    display: inline-block;
    margin: 7px 0; }
  
  .table-mobile-cell-list-item-term {
    font-family: RNHouseSansBold, Arial, Helvetica, sans-serif; }
  
  .table-mobile-cell-list-item-def {
    float: right; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-textarea {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    height: 44px;
    border-radius: 3px;
    padding: 10px 16px;
    border: 1px solid #808080;
    resize: none;
    overflow: hidden;
    box-sizing: border-box; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-checkbox {
    font-size: 18px;
    position: relative;
    padding-right: 40px;
    bottom: 5px;
    display: inline-flex;
    flex-direction: row; }
    .components-checkbox .checkbox {
      cursor: pointer;
      min-width: 27px;
      height: 27px;
      background: #fff;
      border: 2px solid #d8d8d8;
      border-radius: 0.2rem; }
      .components-checkbox .checkbox.checked {
     /*   background: no-repeat center url("assets/images/tick.svg") #ad1982;*/
        border: 2px solid #ad1982; }
      .components-checkbox .checkbox.error {
        border: 4px solid #d70028; }
    .components-checkbox > span {
      position: relative;
      top: 5px;
      vertical-align: top;
      padding-left: 18px;
      font-size: 16px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-button-collapse button {
    margin-top: 4px;
    margin-bottom: 12px;
    text-align: left;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 0;
    background-color: transparent;
    color: #ad1982;
    fill: #ad1982; }
    .components-button-collapse button:hover {
      color: #42145f;
      fill: #42145f; }
    .components-button-collapse button:focus {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto; }
    .components-button-collapse button--disabled {
      cursor: default;
      color: #f1f1f2; }
    .components-button-collapse button svg {
      width: 16px; }
    .components-button-collapse button.action {
      text-decoration: none; }
  
  .components-button-collapse .components-collapse-contents {
    border-radius: 4px;
    background-color: #f7f7f5;
    margin-bottom: 16px; }
    .components-button-collapse .components-collapse-contents p {
      padding: 15px 15px 15px;
      margin: 0; }
      .components-button-collapse .components-collapse-contents p:last-child:not(:only-of-type) {
        padding-top: 0; }
  
  .components-button-collapse .button-disabled {
    margin-top: 16px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-radio-group:first-child {
    border-radius: 4px 0 0 4px;
    border-width: 1px; }
  
  .components-radio-group:last-child {
    border-radius: 0 4px 4px 0; }
  
  .components-radio-group.error {
    border: none;
    display: block; }
  
  .components-radio-group-label {
    display: block;
    margin-bottom: 11px; }
  
  .components-radio-group-options {
    display: flex;
    flex-flow: row wrap; }
    .components-radio-group-options-stacked {
      display: flex;
      flex-flow: column nowrap; }
      .components-radio-group-options-stacked .components-radio-option-and-children-wrapper:not(:first-child) {
        padding-top: 10px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-radio-option-wrapper {
    display: flex; }
    .components-radio-option-wrapper.error {
      border: none;
      display: flex; }
    .components-radio-option-wrapper .components-radio-option-title {
      vertical-align: middle;
      display: inline-block;
      padding: 5px 10px 10px 10px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-radioIcon-container {
    position: relative;
    display: inline-block;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #808080;
    background-color: #fff;
    box-shadow: none; }
    .components-radioIcon-container.error {
      border: 4px solid #d70028; }
  
  .components-radioIcon-container-base {
    position: absolute;
    height: 12px;
    min-width: 12px;
    left: 9px;
    top: 9px;
    border-radius: 6px;
    background-color: transparent; }
    .components-radioIcon-container-base.checked {
      background-color: #ad1982; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-validation-warning .components-arrow-arrow {
    width: 8.48526px;
    height: 8.48526px;
    margin: 1.75737px; }
  
  .components-validation-warning .components-arrow-holder {
    width: 12px;
    height: 6px; }
  
  .components-validation-warning .components-infobox-container {
    border: 1px solid #fdc41a;
    background-color: #fff4d6; }
  
  .components-validation-warning .components-arrow-arrow {
    border: 1px solid #fdc41a;
    background-color: #fff4d6; }
  
  .components-validation-warning .components-arrow-holder {
    left: 10px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .auto-suggest-component .combo-box-container {
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    max-width: 340px; }
    .auto-suggest-component .combo-box-container-open input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
  
  .auto-suggest-component .combo-box-input {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    padding: 0 16px;
    border: 1px solid #808080;
    box-sizing: border-box;
    font-size: 16px;
    font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
   /* background: no-repeat 97% 50% url("assets/images/magnifyingGlass.svg");*/ }
  
  .auto-suggest-component .combo-box-suggestions-container-open {
    max-height: 180px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #808080;
    border-top: none; }
  
  .auto-suggest-component .combo-box-suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  
  .auto-suggest-component .combo-box-suggestion {
    padding: 15px;
    color: #000; }
    .auto-suggest-component .combo-box-suggestion .suggestion-content ::selection {
      background-color: #fff; }
    .auto-suggest-component .combo-box-suggestion-highlighted {
      color: #fff;
      background-color: #ad1982; }
      .auto-suggest-component .combo-box-suggestion-highlighted .suggestion-content ::selection {
        background-color: #ad1982; }
    .auto-suggest-component .combo-box-suggestion .matched {
      font-weight: bold; }
  
  .auto-suggest-component .info-message {
    box-shadow: 1px 2px 2px 1px #999999;
    border-radius: 4px;
    padding: 10px;
    background-color: #f7f7f5;
    width: 100%;
    max-width: 340px;
    display: block;
    box-sizing: border-box; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-button-group {
    display: flex;
    flex-wrap: wrap; }
    .components-button-group div {
      display: flex; }
    .components-button-group .components-button.action {
      margin-left: 40px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .components-status-box {
    border-radius: 4px;
    padding: 20px; }
    .components-status-box .status-heading {
      display: flex; }
      .components-status-box .status-heading .status-icon {
        height: 25px;
        width: 25px;
        vertical-align: bottom; }
      .components-status-box .status-heading .header-icon {
        height: 25px;
        width: 25px;
        display: inline; }
      .components-status-box .status-heading h3 {
        font-size: 16px;
        padding-left: 16px;
        margin-bottom: 0;
        color: #000; }
        @media screen and (min-width: 380px) {
          .components-status-box .status-heading h3 {
            font-size: 20px; } }
    .components-status-box .status-body {
      font-size: 13px; }
      @media screen and (min-width: 768px) {
        .components-status-box .status-body {
          font-size: 14px; } }
      @media screen and (min-width: 540px) {
        .components-status-box .status-body {
          padding-left: 40px; } }
      .components-status-box .status-body p:last-child {
        margin-bottom: 0; }
    .components-status-box.positive {
      border: 1px solid #34a2b2; }
      .components-status-box.positive svg {
        fill: #34a2b2; }
    .components-status-box.negative {
      border: 1px solid #d70028; }
      .components-status-box.negative svg {
        fill: #d70028; }
    .components-status-box.warning {
      border: 1px solid #fdc41a;
      background-color: rgba(253, 196, 26, 0.1); }
      .components-status-box.warning svg {
        fill: #42145f; }
    .components-status-box.noHeading {
      display: flex;
      margin-top: 10px;
      max-width: 660px; }
      .components-status-box.noHeading .status-body {
        padding-left: 16px; }
        .components-status-box.noHeading .status-body > p {
          margin-top: 0px;
          font-size: 16px; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  .calendar-input {
    width: 260px; }
    .calendar-input-picker {
      box-sizing: border-box;
      position: absolute;
      z-index: 100;
      width: 260px;
      border-radius: 4px;
      border: 1px solid #808080;
      border-top: none;
      background-color: #fff; }
      .calendar-input-picker .month-year-container {
        display: flex;
        height: 25px;
        padding: 10px 4px 0;
        text-align: center; }
      .calendar-input-picker .month-year-icon {
        height: 18px; }
        .calendar-input-picker .month-year-icon-wrapper {
          display: inherit;
          width: 25px; }
      .calendar-input-picker .month-year-display {
        flex-basis: 100%; }
        .calendar-input-picker .month-year-display:first-child {
          margin-left: 25px; }
        .calendar-input-picker .month-year-display:last-child {
          margin-right: 25px; }
        .calendar-input-picker .month-year-display span {
          vertical-align: middle; }
          .calendar-input-picker .month-year-display span:first-child {
            padding-right: 5px; }
      .calendar-input-picker .day-picker-list {
        display: flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        list-style: none;
        padding: 0;
        margin: 0; }
      .calendar-input-picker .day-picker-days {
        display: flex;
        text-align: center;
        list-style: none;
        padding: 10px 3px;
        margin: 0;
        border-bottom: 1px solid #000; }
        .calendar-input-picker .day-picker-days li {
          width: 36px;
          font-size: 13px;
          font-weight: bold; }
      .calendar-input-picker .day-picker-item {
        width: 36px;
        height: 36px; }
      .calendar-input-picker .day-button-base {
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: #000;
        border: 1px solid #fff;
        border-radius: 0;
        text-decoration: none; }
        .calendar-input-picker .day-button-base:not(.day-button-disabled):hover {
          border-color: #979797; }
      .calendar-input-picker .day-button-disabled .day-date-base {
        color: #979797; }
      .calendar-input-picker .day-date-base {
        color: #000;
        height: 100%;
        line-height: 36px; }
      .calendar-input-picker .day-date-active:hover {
        color: #ad1982;
        background-color: #f1f1f2; }
      .calendar-input-picker .day-date-selected {
        background-color: #ad1982;
        color: #fff; }
        .calendar-input-picker .day-date-selected:hover {
          background-color: #ad1982;
          color: #fff; }
    .calendar-input-container .components-group-base, .calendar-input-container .components-group-item-border {
      border: none;
      flex-direction: column; }
    .calendar-input-container input {
     /* background: url("assets/images/arrow.png") no-repeat 97% 50% #fff;*/
      background-size: 17px 10px; }
    .calendar-input-container.error {
      border: none; }
      .calendar-input-container.error .components-group-base {
        min-height: 50px; }
      .calendar-input-container.error input {
        border: 4px solid #d70028;
        box-sizing: border-box;
        width: 100%; }
  
  .wrapper-loader {
    margin: 0;
    height: 95px;
    width: 105px; }
  
  svg#loader {
    animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite;
    -webkit-animation: fadeInChildren 1.5s ease 0s 1, spin 1.35s ease-out 0s infinite; }
  
  @keyframes fadeInChildren {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(359deg); } }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg); } }
  
  .page-loader {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: fadeInLoader 500ms ease-in-out 0s 1;
    -webkit-animation: fadeInLoader 500ms ease-in-out 0s 1; }
  
  .page-loader-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 10; }
    .page-loader-content.has-copy {
      background: #fff;
      border: solid 1px #303030;
      height: 70%;
      max-width: 500px;
      max-height: 300px;
      width: 80%; }
  
  .page-loader-copy {
    max-width: 250px;
    padding: 35px 0 0;
    text-align: center;
    width: 80%; }
    @media screen and (max-height: 380px) {
      .page-loader-copy {
        padding-top: 5px; } }
  
  @keyframes fadeInLoader {
    0% {
      opacity: 0.2; }
    50% {
      opacity: 1; } }
  
  @media screen and (min-width: 400px) {
    .term-row {
      display: flex;
      flex-direction: row;
      align-self: flex-start; } }
  
  .term-select {
    display: flex;
    margin-right: 10px; }
    .term-select select {
      margin-right: 10px; }
  
  .term-field:first-child {
    margin-bottom: 15px; }
  
  @media screen and (min-width: 400px) {
    .term-field {
      display: flex;
      flex-direction: column; }
      .term-field:first-child {
        margin-bottom: 0; } }
  
  .term-field-text {
    align-self: center; }
  
  .vb-frm {
    overflow-y: auto
  }
  